import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Landing.css";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import ContentCrausel from "./ContentCrausel";
import SuccessPopUp from "./SuccessPopUp";
import { notification } from "antd";
const featuresData = [
  {
    img: "/assets/hands.svg",
    title: "Get New Leads & Close more Deals",
    content:
      "Kuants helps you with new leads every month for continuous growth of your AUM and your business along with a dedicated RM.",
  },
  {
    img: "/assets/commission.svg",
    title: "Highest Commission Challenge",
    content:
      "Kuants offers industry’s highest revenue share on mutual funds. IF YOU ARE GETTING HIGHER SHARE WITH US, WE WILL MATCH IT!",
  },
  {
    img: "/assets/Groupbranding.svg",
    title: "Personal Branding",
    content:
      "Kuants will assist you in building your own digital brand through websites, social media and enhance soft skills for superior client engagement.",
  },
  {
    img: "/assets/Groupmarketing.png",
    title: "360° Marketing",
    content:
      "Keep your clients engaged by sharing interactive content & latest market news especially curated for you by Kuants team.",
  },
  {
    img: "/assets/Grouptraining-and-support.svg",
    title: "Training & Support",
    content:
      "Get exclusive training on mutual funds, stock markets, IPOs etc, both online and offline with the help of our team of 200+ professionals.",
  },
];
const Landing = () => {
  const [fullName, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [crntAum, setCrntAum] = useState("");
  const [numOfClnt, setNumOfClnt] = useState("");
  const [needHelp, setNeedHelp] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crauseIndex, setCrauselIndex] = useState(0);
  //   console.log("crntAum",crntAum);
  //   console.log("numOfClnt",numOfClnt);
  // console.log("index",crauseIndex);
  const testimonialsName = [
    "Jinesh Shah",
    "Jahnvi Mehta",
    "Suresh Kamble",
    "Mukesh Thakur",
    "Supriya Lakhanpal",
  ];
  const testimonialsImg = [
    "assets/Jinesh Shah.svg",
    "assets/Jahnvi Mehta.svg",
    "assets/Suresh Kamble.svg",
    "assets/Mukesh Thakur.svg",
    "assets/Supriya Lakhanpal.svg"
  ];

useEffect(() => {
  //var myDiv = document.getElementById("fc_frame");
  //alert(myDiv)
    // if(window.location.pathname ==="/landing-page-partner"){
    //  myDiv.style.display = "none";
    // }
  
}, [])

  const sendLimitReach = (type) => {
   notification[type]({
      message: "Request sent limit reached",
      duration: 2,
      closeIcon: <></>,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsModalOpen(true);
    const headers = {
      "Content-Type": "Application/json",
      Accept: "application/json",
    
    };

    axios
      .post(
        config.partnerLead,
        {
          full_name: fullName,
          phone_number: mobile,
          need_help: needHelp ? needHelp : null,
          current_aum: crntAum ? crntAum : null,
          number_of_clients: numOfClnt ? numOfClnt : null,
        },
        { headers: headers }
      )
      .then((res) => {
        console.log("send data to server", res);
        if(res.status === 200){
              setIsModalOpen(true);
        }
       
        setName("")
        setMobile("")
        setNeedHelp("")
        setCrntAum(null)
        setNumOfClnt("")
      })
      .catch((err) => {
        if(err.response.status === 409){
          sendLimitReach("warning")
        }
        console.log("error sending data to server", err);
      });
    //  console.log("submit")
  };

  const needHelpArrya = [
    "Need help with",
    "Lead Generation",
    "Higher commission",
    "Marketing Support",
    "Operational Support",
    "Overall Business Growth ",
  
  ];

  const aumRangeArray = [
    "Current AUM range",
    "0 to 25 lakhs",
    "50 lakhs to 1cr",
    "1cr to 2cr",
    "2cr+",
  ];
  const numOfClntArray = [
    "No. of clients",
    "0 to 5",
    "6 to 15",
    "16 to 30",
    "31 to 50",
    "50+",
  ];

  const onInputChange = e => {
    const { value } = e.target;
    
 
    const re = /^[a-zA-Z\s]*$/;
    if (value === "" || re.test(value)) {
      setName(value);
   
    }
  }



  return (
    <div className="landing-page-main-cont">
      <div style={{background:"#683fdb"}}>
      <div className="partnership-main-container">
        <div className="logo-rgster-btn-cont">
        <a href="https://partners.kuants.in" target="_blank">
          <img src="assets/Logo.svg" alt="logo..."  style={{cursor:"pointer"}}/>
          </a>
          <a href="https://partners.kuants.in" target="_blank">
          <button>Register now</button>
          </a>
        </div>
        <div className="form-content-top-cont">
          <div className="p-m-left">
            <h2 className="main-heading-style partner-heading">
              Expand your wealth management business with Kuants
            </h2>
            <p>
              Join us and experience the power of technology and personalized
              assistance - as you earn 2X more and achieve your dreams.
            </p>
            <p>
              Your success is our top priority - which is why we offer the
              latest technology and expert training to help you earn twice as
              much and thrive in your career.
            </p>
          </div>
          <div className="p-m-right">
            <h1>Partner with Kuants today!</h1>
            <form
              action="submit"
              className="partner-dtl-frm"
              onSubmit={(e) => handleSubmit(e)}
            >
              <input
                type="text"
                required
                name="name"
                placeholder="Full name*"
                value={fullName}
                onChange={onInputChange}
              />
              <input
                type="number"
                required
                name="phone"
                placeholder="Phone number*"
                value={mobile}
                onChange={(e) => {
                  if (e.target.value.length < 11) {
                    setMobile(e.target.value);
                  }
                }}
              />
              <select
                placeholder="Need help with"
                onChange={(e) => setNeedHelp(e.target.value)}
                required
          
              >
                {needHelpArrya.map((data, index) => {
                  return (
                    <option
                      value={ index === 0 ?"":data}
                      disabled={index === 0}
                      selected={index === 0}
                 
                    >
                      {data}
                    </option>
                  );
                })}
              </select>
              <div className="crnt-aum-nf-clnt-slect-cont">
                <select
                  // placeholder="Need help with"
                  onChange={(e) => setCrntAum(e.target.value)}
                >
                  {aumRangeArray.map((data, index) => {
                    return (
                      <option
                        value={data}
                        disabled={index === 0}
                        selected={index === 0}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
                <select
                  // placeholder="Need help with"
                  onChange={(e) => setNumOfClnt(e.target.value)}
                >
                  {numOfClntArray.map((data, index) => {
                    return (
                      <option
                        value={data}
                        disabled={index === 0}
                        selected={index === 0}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button className="rqst-cll-btn" type="submit" value="Submit">
                Request for call back
              </button>
            </form>
          </div>
        </div>
      </div>
      </div>

      <div className="partnership-program hero-container">
        <div className="benefits">
          <h2 className="heading-style-one">
            Simplifying your success with Kuants
          </h2>
          <div className="b-card-container">
            {featuresData.map((item, index) => (
              <div className="card" key={index}>
                <img src={`${item.img}`} alt={`${item.title}`} />
                <p className="card-title">{item.title}</p>
                <p className="card-content">{item.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="testimonials-conainer">
          <h1 className="heading-style-one">Testimonials</h1>
          <div className="profile-div">
            <img
              src={testimonialsImg[crauseIndex]}
              width={"100%"}
              height={"100%"}
            />
          </div>
          <h4 className="card-title">{testimonialsName[crauseIndex]}</h4>
          <ContentCrausel
            setCrauselIndex={setCrauselIndex}
            crauseIndex={crauseIndex}
          />
        </div>

        <div className="callback-container">
          <h2 className="heading-style-one">
            Need more info, get in touch with us.
          </h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              required
              type="text"
              name="name"
              placeholder="Full Name"
              value={fullName}
              onChange={onInputChange}
            />
            <input
              required
              type="number"
              name="phone"
              placeholder="Phone number"
              value={mobile}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setMobile(e.target.value);
                }
              }}
            />
            <input
              type="submit"
              className={
                window.location.pathname === "/landing-page-partner"
                  ? "rqst-call-btn-for-lnding"
                  : "rqst-call-btn"
              }
              value="Submit"
            />
          </form>
        </div>
      </div>
      <SuccessPopUp isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default Landing;
