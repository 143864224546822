import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import { StickyContainer, Sticky } from 'react-sticky';
export default class Header extends Component {
    state = {
        activeNav: '',
        flag: false,
        agentUrl: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const pathName = window.location.pathname.split("/")[1]
        // console.log(pathName);
        // let agentUrl=false;
        if (`/${pathName}`=== "/rapipay-agent") {
            this.setState({ agentUrl: !this.state.agentUrl })
        }
    }
   componentDidUpdate(){
    const pathName = window.location.pathname.split("/")[1]
    // let agentUrl=false;
    if (`/${pathName}` !== "/rapipay-agent" && this.state.agentUrl===true) {
        this.setState({ agentUrl: !this.state.agentUrl })
    }
   }

    render() {
        const handleMenuClick = () => {
            this.state.activeNav === '' ? this.setState({ activeNav: 'active-nav' }) : this.setState({ activeNav: '' })
            this.setState({ flag: !this.state.flag })
            }
        
        return (
            <div className={window.location.pathname==="/landing-page-partner"?'flex-header-hide':'flex header'}>
                {/* main styling for landing */}
                {
                !this.state.agentUrl &&
                <a href='/'>
                    <img src="/assets/Logo.png" alt='kuants_logo' className="main-logo" />
                </a>}
                {/* to move the nav option to the right near Rapipay logo */}
                {this.state.agentUrl &&
                <a href='/rapipay-agent' style={{flex:1}}>
                    <img src="/assets/Logo.png" alt='kuants_logo' className="main-logo" />
                </a>}
                {   !this.state.agentUrl &&
                    <div className={window.location.pathname ==="/landing-page-partner"? "menu-container-hide":'menu-container'}>
                    <img src='/assets/bars-solid.svg' alt='hamburger-icon' className='menu-icon' onClick={handleMenuClick} />
                    <nav className={this.state.activeNav}>
                        {this.state.flag ? <img src='/assets/cross.png' alt='cross-icon' className='menu-icon cross-icon' onClick={handleMenuClick} /> : null}
                        <NavLink
                            to="/"
                            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                            onClick={handleMenuClick}>
                            Home
                        </NavLink>
                        <NavLink
                            to="/about"
                            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                            onClick={handleMenuClick}
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/partner-program"
                            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                            onClick={handleMenuClick}
                        >
                            Partner
                        </NavLink>

                       {!this.state.agentUrl && <div className='nav-dl-btn-container'>
                            <a href='https://play.google.com/store/apps/details?id=com.kuants' target='_blank' rel="noreferrer"><button className="nav-dl-btn">Download App</button></a>
                        </div>
                         }
                    </nav>
                    
                </div>
                }
                
                {this.state.agentUrl &&  <img src="/assets/rapipay-logo.png" alt='kuants_logo ' className="rp-logo" style={{width:"12%"}}/>
                    }
            </div>
        )
    }
}
