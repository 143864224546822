import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
// const WorkingCompo=lazy(() => import('./WorkingCompo'));

import { useParams } from "react-router-dom";
import axios from 'axios';
import config from '../config';


const AgentDataCompo = () => {
    let { number } = useParams();
    const [agentData, setAgentData] = useState(null)
    //flag to check if number exists or is correct
    const [flag,setFlag] = useState(true)
    const fetchData = () => {
        let formObj = { contact_number: number }
        axios.post(config.agentData, formObj).then((res) => {
            if (res.status === 200) {
            setAgentData(res.data.agent_client_data)
            console.log(res.data);
            }
        }).catch(error => {
            setFlag(false)
         })

    }
    useEffect(() => {
        fetchData()
    }, [])
   
    return (
        <div className='agent_data-container'>
            { agentData?
            <table>
                <tbody>
                <tr>
                    <th>User Id</th>
                    <th>Client name</th>
                    <th>Last transaction</th>
                    <th>AUM</th>
                    <th>Mobile Number</th>
                    <th>Last Communication</th>
                    <th>Status</th>
                    <th>Tags</th>
                </tr>
                  {
                  agentData.map((client)=>{ 
                    return (
                        <tr key={client.user_id}>
                            <td>{client.user_id}</td>
                            <td>{client.full_name}</td>
                            <td>{client.last_transaction.split("T")[0]}</td>
                            <td>{client.total_aum}</td>
                            <td>{client.mobile_number}</td>
                            <td>{client.last_communication?client.last_communication.split("T")[0]:"-"}</td>
                            <td>{client.client_status}</td>
                            <td>{client.client_tags}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>:flag?<h1>Loading Data...</h1>:<h1>No data found</h1>}
        </div>
    )
}
export default AgentDataCompo