import { Modal } from 'antd'
import React from 'react'
 import "./SuccessPopUp.css"
export default function SuccessPopUp(props) {
  
    const handleOk = () => {
      props.setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      props.setIsModalOpen(false);
    };
  


  return (
    <Modal className='success-pop-up' open={props.isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <div className='main-cont'>
            <img src="/assets/success.svg"/>
            <h1>Thank you for your interest.</h1>
            <h4>Our team will get in touch with you shortly</h4>
          </div>
    </Modal>
  )
}
