import React from "react";
import {Helmet} from "react-helmet";
class B63 extends React.Component {
    constructor(props) {
        super(props);
    
     
        this.state = {
          data: [],
          phone: "",
        };
      }

      componentWillMount() {
       
      }    

      render() {
        return (
          <div className="mainArticleContainer">
            <div>           

          <Helmet>
            <title>What is Slippage</title>
            <meta name= " description" content="Slippage in algorithmic trading refers to the differences in actual price of exection and the desired price of execution. While in backtest, we always get the desired price, but same is not the scenario while trading live. This article highlights the key differences and importance of slipapge" />
            <link rel="canonical" href="https://kuants.in/blog/algorithmic-trading/What-is-Slippage"></link> 
        </Helmet>
        <div className="row mx-2 mt-4 mb-5">
          <div className="col col-xl-2 mt-4 text-center">
<img src="/images/books.png" width="50%"></img>
<ul>
<li className="border-bottom  sidebarblog"><a href="/blog" target="blank"> All Articles</a></li>

  <li className="border-bottom sidebarblog"><a href="/blog/algorithmic-trading" target="blank">Algorithmic Trading</a></li>
  <li className="border-bottom sidebarblog"><a href="/blog/technical-analysis" target="blank"> Technical Analysis</a></li>
  <li className="border-bottom sidebarblog"><a href="/blog/stock-markets" target="blank"> Stock Markets</a></li>
  <li className="border-bottom sidebarblog"><a href="/blog/algolab" target="blank">Algolab</a></li>
  <li className="border-bottom sidebarblog"><a href="/blog/smart-marketplace" target="blank">SMART Marketplace</a></li>

</ul>
</div>
<div className="col col-xl-1 mt-4 "></div>

<div className="col col-xl-8 mt-4 ">
<ul class="breadcrumb">
  <li><a href="/">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li><a href="/blog/algorithmic-trading">Algorithmic Trading</a></li>
  <li>What is Slippage?</li>
</ul>
      <div className=" h12 mb-5">
  What is Slippage  and why is it necessary? 
            </div>  
            Slippage in stock trading is defined as the gap between the price at which an order is executed and the price at which the order was sent for processing to a stock exchange. When we place the orders, it takes a small amount of time, usually in milliseconds to reach the exchange. What might have been an execution price of INR 100, might change to INR 100.01 by the time it is executed. This price difference is known as slippage and it plays an important role in the development of strategies and trading systems. 
             <br></br>             <br></br>

             Let us consider the following example to understand how this happens:  Suppose the strategy buys at 100 and sells at 102, with a stop loss at 99 and the success of this strategy is 70% over 50 trades. Hence the profit generated by this strategy is equal to the difference between 50 times success rate times target and 50 times success rate times stop-loss, that is 50*0.7*2 - 50*0.7*1=35

             <br></br>
             Now, let's consider 10 paisa slippage on all trades. This means that the strategy will be at 100.1, sell at a target of 101.9, and stop-loss at 99.1. Which the same success rate, the following will be the net profit:

             <br></br>
             =50*.7*1.8-50*.7*1.2


<br></br>
=21

<br></br>
<b>The returns decreased by 45% just by adding a slippage of 10 paise.</b>
<br></br>

<br></br>
In the real-world, the slippage can be more than 10 paise and the results will vary greatly from the backtest results. There are multiple models through with slippage can be adjusted in backtest results, the most prominent ones are as follows: 
<br></br>

<br></br>


<b>Time based Slippage:</b>
<br></br>The difference between price of execution and actual price is often found to be maximum during highly volatile periods of the market, which is during the first 15 minutes, from 9:15 to 9:30 in the morning and from 3:15 to 3:30 pm when the market closes. In cross-sectional slippage, an empirical value of slippage is evaluated across different times of trading hours during the day. For example, from 9:15 to 9:30, slippage can be 0.02%, from 9:30 to 12, slippage can be 0.01%, from 12 to 3pm, it is 0.005%, and from 3 to 3:30, it’s 0.02%.
<br></br>

<br></br>
<b>Stock Based:</b>
<br></br> Certain stocks are more heavily traded and have higher volatility than others and greater is the volatility, the higher is the probability of high slippage. In this method, the stocks are classified into high, medium and low slippage models and the respective slippage values are fixed for each stock. 
<br></br>


<br></br>
<b>Fixed Slippage:</b>
<br></br>
In a fixed slippage model, a fixed slippage % is considered across all market times and stocks. The value is generally taken as a qualitative estimate and can range from exchange to exchange. For example, in the US, the value is generally taken as 0.05%, while in India, it ranges from 0.01% to 0.02%. Higher the value of slippage, more conservative the results would be in backtesting. 

<br></br><br></br>

Algolab uses a fixed slippage model of 0.01%, i.e. every time an order is processed in backtests, a value of 0.01% of total turnover of that trade will be deducted, both from the entry and exit side. While this will result in lower returns in backtest, it will always help in making sure that the results are closely matched with live market scenarios.


<br></br><br></br>
<div className="row mb-5">
  <div className="col col-xl-12 text-center">
    <div className="btn btn-primary">
      Visit Algolab
    </div>
  </div>
</div>
          </div></div></div>
</div>

              )}}

  export default B63;
