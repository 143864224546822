import { Carousel } from "antd";
import React,{useEffect} from "react";
import "./ContentCrausel.css";
export default function ContentCrausel(props) {
  const para = [
    "I've been in the wealth management industry for over a decade and I have to say, Kuants is a game changer. Their innovative approach to client onboarding and mutual fund distribution has saved me so much time and effort. With the app, I can manage all my clients easily from my phone - it's been a true lifesaver!",
    "Kuants Wealth has completely transformed the way I manage my clients. With their app, everything is digital and paperless, making my job a lot easier. Plus, their expert team is always there to provide support whenever I need it. I highly recommend Kuants Wealth to anyone in the wealth management business.",
    "I was hesitant to switch to a new wealth management platform, but after trying out Kuants Wealth, I'm so glad I made the change. Their platform is incredibly user-friendly and the team is always available to answer any questions I have. Plus, the time I've saved on paperwork has been a game-changer for me. I highly recommend Kuants Wealth to anyone looking to simplify their wealth management process.",
    "Kuants Wealth has revolutionized the way I work. With their innovative approach and advanced technology, managing my clients has never been easier. I love how their platform makes everything so simple and organized. And the best part is that I can manage everything from my phone - it's truly amazing!",
    "I've been in the wealth management business for years, but Kuants Wealth has taken things to a whole new level. Their app is intuitive and easy to use, and their team is always available to offer support and guidance. I especially love how everything is digital and paperless - it's a huge time-saver! I highly recommend Kuants Wealth to anyone looking to simplify their wealth management process.",
  ];

  const handelIndex=(para)=>{
    props.setCrauselIndex(para)
   
    // console.log("Handel Index",para+1)

  }
  const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        touchMove: true,
      };


  return (
    <Carousel {...settings} className="content-crausel" beforeChange={handelIndex}>
      {para.map((data,index) => {
        return <p className="content"><i>"{data}"</i></p>;
       
      })}
    </Carousel>
  );
}
